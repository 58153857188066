import React from "react";
import "./footer.css"


export const Footer = () => {
  return (
    <div className={`footer-container`}>
        THANK YOU
    </div>
  );
}
